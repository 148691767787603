<template>
  <b-modal
    id="modal-cash-report"
    hide-footer
    ok-title="Tutup"
    size="lg"
    class="custom__modal"
  >
    <template #modal-header>
      <div
        class="text-primary fw-bold-600 pt-25 cursor-pointer"
        v-if="isAdditionalCash || isCashChange"
        @click="goBack(isAdditionalCash ? 'additional' : 'cash')"
      >
        <feather-icon
          size="16"
          class="text-primary fw-bold-800"
          icon="ArrowLeftIcon"
        />
        Kembali
      </div>
      <div
        :class="isAdditionalCash || isCashChange ? 'mx-auto pr-2' : 'mx-auto'"
      >
        <div class="size20 fw-bold-600 text-dark" v-if="isAdditionalCash">
          Detail Cash Tambahan
        </div>
        <div class="size20 fw-bold-600 text-dark" v-else-if="isCashChange">
          Kembalian
        </div>
        <div class="size20 fw-bold-600 text-dark" v-else>Laporan Kas Kecil</div>
      </div>
    </template>
    <div class="p-2" v-if="isAdditionalCash">
      <div
        class="d-flex align-items-center justify-content-between"
        style="margin-bottom: 12px"
      >
        <h5 class="size14 fw-bold-700 mb-0">Cash Tambahan</h5>
        <h5 class="size14 fw-bold-700 mb-0">Deskripsi</h5>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-2"
        style="margin-bottom: 6px"
        v-for="(item, index) in detailResultList.additional_list"
        :key="index"
      >
        <h5 class="size14 fw-bold-600 mb-0">
          {{ formatToRupiah(item.amount) }}
        </h5>
        <h5 class="size14 fw-bold-600 mb-0">
          {{ item.description }}
        </h5>
      </div>
      <b-form-group class="custom__form--input">
        <label for="v-closing_cash">Total Cash Tambahan</label>
        <b-input-group prepend="Rp">
          <b-form-input
            id="v-closing_cash"
            v-model="detailResultList.additional_cash"
            class="custom__input"
            type="text"
            disabled
          />
        </b-input-group>
      </b-form-group>
    </div>
    <div class="p-2" v-if="isCashChange">
      <div
        class="d-flex align-items-center justify-content-between"
        style="margin-bottom: 12px"
      >
        <h5 class="size14 fw-bold-700 mb-0">No Invoice</h5>
        <h5 class="size14 fw-bold-700 mb-0">Kembalian</h5>
      </div>
      <div
        class="d-flex align-items-center justify-content-between"
        style="margin-bottom: 6px"
        v-for="item in detailResultList.cash_change_list"
        :key="item.uuid"
      >
        <router-link
          class="text-primary"
          :to="'invoice/' + item.uuid"
          target="_blank"
        >
          {{ item.invoice_number }}
        </router-link>
        <h5 class="size14 fw-bold-600 mb-0">
          {{ formatToRupiah(item.cash_change) }}
        </h5>
      </div>
    </div>
    <b-row class="p-2" v-if="!isAdditionalCash && !isCashChange">
      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label>Nama Kasir</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.operator_name }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label>Waktu Buka</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.opening_time }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label>Waktu Tutup</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.closing_time }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group class="custom__form--input">
          <label>Jumlah Penjualan</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.transaction_count }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group class="custom__form--input">
          <label>Jumlah Item</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.product_count }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <hr class="my-1" style="border-top: 1px solid #7a7f94" />
      </b-col>

      <!-- <b-col cols="3">
                <b-form-group class="custom__form--input">
                    <label>Jumlah Tunai</label>
                    <b-form-input
                        id="v-name"
                        v-model="detailResult.cash_count"
                        class="custom__input"
                        type="text"
                        disabled
                    />
                </b-form-group>
            </b-col>
            <b-col cols="9">
                <b-form-group class="custom__form--input">
                    <label>Total Tunai</label>
                    <b-form-input
                        id="v-name"
                        v-model="detailResult.cash_amount"
                        class="custom__input"
                        type="text"
                        disabled
                    />
                </b-form-group>
            </b-col> -->

      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label>Cash Awal</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.opening_cash | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label for="v-additional_cash" class="d-flex justify-content-between">
            Cash Tambahan
            <span
              @click="goToAdditionalCashDetail()"
              class="text-primary cursor-pointer"
            >
              Lihat Detail
              <feather-icon
                size="12"
                class="text-primary fw-bold-800"
                icon="ArrowRightIcon"
              />
            </span>
          </label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.additional_cash | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <!-- <b-col cols="12">
                <b-form-group class="custom__form--input">
                    <label >Closing Cash</label>
                    <div class="custom__input px-1 py-1">
                        {{ detailResult.closing_cash | formatAmount }}
                    </div>
                </b-form-group>
            </b-col> -->

      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label for="v-amount" class="d-flex justify-content-between">
            Cash Masuk
            <span
              @click="goToCashInDetail()"
              class="text-primary cursor-pointer"
            >
              Lihat Detail
              <feather-icon
                size="12"
                class="text-primary fw-bold-800"
                icon="ArrowRightIcon"
              />
            </span>
          </label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.cash_amount | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label for="v-amount" class="d-flex justify-content-between">
            Kembalian
            <span
              @click="goToCashChangeDetail()"
              class="text-primary cursor-pointer"
            >
              Lihat Detail
              <feather-icon
                size="12"
                class="text-primary fw-bold-800"
                icon="ArrowRightIcon"
              />
            </span>
          </label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.cash_change | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label for="v-amount" class="d-flex justify-content-between">
            Cash Keluar
            <span
              @click="goToCashOutDetail()"
              class="text-primary cursor-pointer"
            >
              Lihat Detail
              <feather-icon
                size="12"
                class="text-primary fw-bold-800"
                icon="ArrowRightIcon"
              />
            </span>
          </label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.cash_out | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label>Cash Akhir</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.cash_summary | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <!-- <b-col cols="12">
                <b-form-group class="custom__form--input">
                    <label>Total Kembalian</label>
                    <div class="custom__input px-1 py-1">
                        {{ detailResult.cash_change | formatAmount }}
                    </div>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-form-group class="custom__form--input">
                    <label>Total Pengeluaran Lainnya</label>
                    <div class="custom__input px-1 py-1">
                        {{ detailResult.cash_payment | formatAmount }}
                    </div>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-form-group class="custom__form--input">
                    <label>Jumlah Tambahan Cash</label>
                    <div class="custom__input px-1 py-1">
                        {{ detailResult.additional_cash | formatAmount }}
                    </div>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-form-group class="custom__form--input">
                    <label>Closing Cash</label>
                    <div class="custom__input px-1 py-1">
                        {{ detailResult.cash_summary | formatAmount }}
                    </div>
                </b-form-group>
            </b-col> -->

      <!-- <b-col cols="12">
                <b-form-group class="custom__form--input">
                    <label>Saldo Akhir</label>
                    <div 
                        :class="{ 'text-success': detailResult.cash_difference > 0, 'text-danger': detailResult.cash_difference < 0 }" 
                        class="custom__input px-1 py-1"
                    >
                        {{ detailResult.cash_difference | formatAmount }}
                    </div>
                </b-form-group>
            </b-col> -->

      <b-col cols="12">
        <hr class="my-1" style="border-top: 1px solid #7a7f94" />
      </b-col>

      <b-col cols="3">
        <b-form-group class="custom__form--input">
          <label>Jumlah Transfer Bank</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.transfer_count }}
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group class="custom__form--input">
          <label>Total Transfer Bank</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.transfer_amount | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group class="custom__form--input">
          <label>Jumlah Kredit atau Debit</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.card_count }}
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group class="custom__form--input">
          <label>Total Kredit atau Debit</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.card_amount | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group class="custom__form--input">
          <label>Jumlah Giro</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.giro_count }}
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group class="custom__form--input">
          <label>Total Giro</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.giro_amount | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group class="custom__form--input">
          <label>Jumlah Deposit</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.wallet_count }}
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group class="custom__form--input">
          <label>Total Deposit</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.wallet_amount | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group class="custom__form--input">
          <label>Jumlah Piutang</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.piutang_count }}
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group class="custom__form--input">
          <label>Total Piutang</label>
          <div class="custom__input px-1 py-1">
            {{ detailResult.piutang_amount | formatAmount }}
          </div>
        </b-form-group>
      </b-col>

      <!-- <b-col cols="12">
        <hr class="my-1" style="border-top: 1px solid #7a7f94" />
      </b-col>

      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label class="fw-bold-800">Produk</label>
          <div
            class="custom__input custom__input--product px-1 py-1 mb-1"
            v-for="item in detailResult.order_list"
            :key="item.uuid"
          >
            <b-row align-v="center">
              <b-col cols="4">
                <div class="fw-bold-400">No. Invoice</div>
                <div class="fw-bold-500 text-primary">
                  <b-link
                    @click="
                      $router.push({
                        name: 'invoice.index',
                        params: { id: item.uuid },
                        query: { page: 1 },
                      })
                    "
                  >
                    {{ item.invoice_number }}
                  </b-link>
                </div>
              </b-col>
              <b-col cols="2" class="text-center">
                <div class="fw-bold-400">Jumlah Produk</div>
                <div class="fw-bold-700">
                  {{ item.qty }}
                </div>
              </b-col>
              <b-col cols="3" class="text-center">
                <div class="fw-bold-400">Total Harga</div>
                <div class="fw-bold-700">
                  {{ item.total | formatAmount }}
                </div>
              </b-col>
              <b-col cols="3">
                <div class="fw-bold-400">Nama Pelanggan</div>
                <div class="fw-bold-700">
                  {{ item.customer_name }}
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form-group>
      </b-col> -->
    </b-row>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BRow,
  BCol,
  BContainer,
  BTable,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BIconTelephoneMinusFill,
  BLink,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";

import moment from "moment";

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BContainer,
    BTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BLink,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  data() {
    const newData = new Date();
    const momentDate = moment(newData);
    const days = [
      "Minggu",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jumat",
      "Sabtu",
    ];
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const formattedDate = `${days[momentDate.day()]}, ${momentDate.format(
      "DD"
    )} ${months[momentDate.month()]} ${momentDate.format("YYYY HH:mm")}`;

    return {
      moment,
      isLoading: false,
      user: JSON.parse(localStorage.getItem("userData")),
      date: formattedDate,
      formPayload: {
        closing_cash: 0,
      },
      detailResultList: {},
      isAdditionalCash: false,
      isCashChange: false,
    };
  },
  props: {
    getData: {
      type: Function,
    },
    detailResult: {
      type: Object,
    },
  },
  watch: {
    detailResult(newValue) {
      this.detailResultList = newValue;
    },
  },
  methods: {
    getRupiah() {
      const rupiah = document.getElementById("v-amount");
      rupiah.value = this.formatRupiah(rupiah.value);
      this.formPayload.closing_cash = rupiah.value;
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, "").toString();
      const split = number_string.split(",");
      const sisa = split[0].length % 3;
      let rupiah = split[0].substr(0, sisa);
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        const separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
      return prefix == undefined ? rupiah : rupiah || "";
    },
    formatToRupiah(amount) {
      const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    goToAdditionalCashDetail() {
      this.$bvModal.hide("modal-cash-report");
      this.isAdditionalCash = true;
      this.$bvModal.show("modal-cash-report");
    },
    goToCashChangeDetail() {
      this.$bvModal.hide("modal-cash-report");
      this.isCashChange = true;
      this.$bvModal.show("modal-cash-report");
    },
    goBack(name) {
      this.$bvModal.hide("modal-cash-report");
      if (name === "additional") {
        this.isAdditionalCash = false;
      } else {
        this.isCashChange = false;
      }
      this.$bvModal.show("modal-cash-report");
    },
    goToCashInDetail() {
      const openingTime = this.detailResult.opening_time;
      const date = new Date(openingTime);
      const formattedDate = date.toISOString().split("T")[0];
      const routeParams = {
        name: "transaction.index",
        query: { date: formattedDate },
      };
      const routePath = this.$router.resolve(routeParams).href;
      window.open(routePath, "_blank");
    },
    goToCashOutDetail() {
      const openingTime = this.detailResult.opening_time;
      const date = new Date(openingTime);
      const formattedDate = date.toISOString().split("T")[0];
      const routeParams = {
        name: "payout.index",
        query: { date: formattedDate },
      };
      const routePath = this.$router.resolve(routeParams).href;
      window.open(routePath, "_blank");
    },
    closeModal() {
      this.$bvModal.hide("modal-cash-report");
    },
    cleanUpForm() {
      this.formPayload.closing_cash = 0;
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (key === "closing_cash") {
            // eslint-disable-next-line radix
            form.append(
              key,
              parseFloat(this.preformatFloat(this.formPayload[key].toString()))
            );
          } else {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      return form;
    },
    closeItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      this.$store
        .dispatch("pettyCash/postClose", {
          query: "",
          payload: form,
          uuid: this.detailResultList.uuid,
        })
        .then(() => {
          this.getData();
          successNotification(this, "Success", "Kas Kecil berhasil ditutup");
          this.cleanUpForm();
          this.$bvModal.hide("modal-cash-report");
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    updateFormattedDate() {
      const newDate = new Date();
      const momentDate = moment(newDate);
      const days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      this.date = `${days[momentDate.day()]}, ${momentDate.format("DD")} ${
        months[momentDate.month()]
      } ${momentDate.format("YYYY HH:mm")}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.button__group {
  display: flex;
  width: 100%;
  justify-content: end;

  .button__cancel {
    background-color: #ffffff !important;
    border-color: $light--3 !important;
    color: $dark !important;
  }

  .button__save {
    background-color: #45b6ab !important;
    border-color: #45b6ab !important;
  }
}

.custom__input--product {
  height: 79px !important;
}
</style>
