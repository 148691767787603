<template>
  <b-modal
    id="modal-close-cash"
    hide-header-close
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    ok-title="Tutup"
    size="lg"
    class="custom__modal"
    @show="updateFormattedDate"
  >
    <template #modal-header>
      <div
        class="text-primary fw-bold-600 pt-25 cursor-pointer"
        v-if="isAdditionalCash || isCashChange"
        @click="goBack(isAdditionalCash ? 'additional' : 'cash')"
      >
        <feather-icon
          size="16"
          class="text-primary fw-bold-800"
          icon="ArrowLeftIcon"
        />
        Kembali
      </div>
      <div
        :class="isAdditionalCash || isCashChange ? 'mx-auto pr-4' : 'mx-auto'"
      >
        <div class="size20 fw-bold-600 text-dark" v-if="isAdditionalCash">
          Detail Cash Tambahan
        </div>
        <div class="size20 fw-bold-600 text-dark" v-else-if="isCashChange">
          Kembalian
        </div>
        <div class="size20 fw-bold-600 text-dark" v-else>Detail Kas Kecil</div>
      </div>
    </template>
    <div class="p-2" v-if="isAdditionalCash">
      <div
        class="d-flex align-items-center justify-content-between"
        style="margin-bottom: 12px"
      >
        <h5 class="size14 fw-bold-700 mb-0">Cash Tambahan</h5>
        <h5 class="size14 fw-bold-700 mb-0">Deskripsi</h5>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mb-2"
        style="margin-bottom: 6px"
        v-for="(item, index) in detailResultList.additional_list"
        :key="index"
      >
        <h5 class="size14 fw-bold-600 mb-0">
          {{ formatToRupiah(item.amount) }}
        </h5>
        <h5 class="size14 fw-bold-600 mb-0">
          {{ item.description || "-" }}
        </h5>
      </div>
      <b-form-group class="custom__form--input">
        <label for="v-closing_cash">Total Cash Tambahan</label>
        <b-input-group prepend="Rp">
          <b-form-input
            id="v-closing_cash"
            v-model="detailResultList.additional_cash"
            class="custom__input"
            type="text"
            disabled
          />
        </b-input-group>
      </b-form-group>
    </div>
    <div class="p-2" v-if="isCashChange">
      <div
        class="d-flex align-items-center justify-content-between"
        style="margin-bottom: 12px"
      >
        <h5 class="size14 fw-bold-700 mb-0">No Invoice</h5>
        <h5 class="size14 fw-bold-700 mb-0">Kembalian</h5>
      </div>
      <div
        class="d-flex align-items-center justify-content-between"
        style="margin-bottom: 6px"
        v-for="item in detailResultList.cash_change_list"
        :key="item.uuid"
      >
        <router-link
          class="text-primary"
          :to="'invoice/' + item.uuid"
          target="_blank"
        >
          {{ item.invoice_number }}
        </router-link>
        <h5 class="size14 fw-bold-600 mb-0">
          {{ formatToRupiah(item.cash_change) }}
        </h5>
      </div>
    </div>
    <b-row class="p-2" v-if="!isAdditionalCash && !isCashChange">
      <b-col cols="12" md="6">
        <b-form-group class="custom__form--input">
          <label for="v-name">Nama User</label>
          <b-form-input
            id="v-name"
            v-model="user.name"
            class="custom__input"
            type="text"
            placeholder="Nama"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group class="custom__form--input">
          <label for="v-date">Waktu Tutup</label>
          <b-form-input
            id="v-date"
            v-model="date"
            class="custom__input"
            type="text"
            placeholder="Tanggal"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label for="v-amount">Cash Awal</label>
          <b-input-group prepend="Rp">
            <b-form-input
              id="v-amount"
              v-model="detailResultList.opening_cash"
              class="custom__input"
              type="text"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group class="custom__form--input">
          <label for="v-amount" class="d-flex justify-content-between">
            Cash Masuk
            <span
              @click="goToCashInDetail()"
              class="text-primary cursor-pointer"
            >
              Lihat Detail
              <feather-icon
                size="12"
                class="text-primary fw-bold-800"
                icon="ArrowRightIcon"
              />
            </span>
          </label>
          <b-input-group prepend="Rp">
            <b-form-input
              id="v-amount"
              v-model="detailResultList.cash_amount"
              class="custom__input"
              type="text"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group class="custom__form--input">
          <label for="v-additional_cash" class="d-flex justify-content-between">
            Cash Tambahan
            <span
              @click="goToAdditionalCashDetail()"
              class="text-primary cursor-pointer"
            >
              Lihat Detail
              <feather-icon
                size="12"
                class="text-primary fw-bold-800"
                icon="ArrowRightIcon"
              />
            </span>
          </label>
          <b-input-group prepend="Rp">
            <b-form-input
              id="v-additional_cash"
              v-model="detailResultList.additional_cash"
              class="custom__input"
              type="text"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group class="custom__form--input">
          <label for="v-cash_change" class="d-flex justify-content-between">
            Kembalian
            <span
              @click="goToCashChangeDetail()"
              class="text-primary cursor-pointer"
            >
              Lihat Detail
              <feather-icon
                size="12"
                class="text-primary fw-bold-800"
                icon="ArrowRightIcon"
              />
            </span>
          </label>
          <b-input-group prepend="Rp">
            <b-form-input
              id="v-cash_change"
              v-model="detailResultList.cash_change"
              class="custom__input"
              type="text"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group class="custom__form--input">
          <label for="v-cash_out" class="d-flex justify-content-between">
            Pembayaran Lainnya
            <span
              @click="goToCashOutDetail()"
              class="text-primary cursor-pointer"
            >
              Lihat Detail
              <feather-icon
                size="12"
                class="text-primary fw-bold-800"
                icon="ArrowRightIcon"
              />
            </span>
          </label>
          <b-input-group prepend="Rp">
            <b-form-input
              id="v-cash_out"
              v-model="detailResultList.cash_payment"
              class="custom__input"
              type="text"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group class="custom__form--input">
          <label for="v-closing_cash">Total Cash</label>
          <b-input-group prepend="Rp">
            <b-form-input
              id="v-closing_cash"
              v-model="formPayload.closing_cash"
              class="custom__input"
              type="text"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="button__group" v-if="!isAdditionalCash && !isCashChange">
      <b-button class="button__cancel fw-bold-700 size13" @click="closeModal()">
        Batal
      </b-button>
      <b-button class="button__save fw-bold-700 size13" @click="closeItem()">
        Tutup
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BRow,
  BCol,
  BContainer,
  BTable,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BIconTelephoneMinusFill,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";

import moment from "moment";
import "moment/locale/id";

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BContainer,
    BTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  data() {
    const newData = new Date();
    const momentDate = moment(newData);
    const days = [
      "Minggu",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jumat",
      "Sabtu",
    ];
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const formattedDate = `${days[momentDate.day()]}, ${momentDate.format(
      "DD"
    )} ${months[momentDate.month()]} ${momentDate.format("YYYY HH:mm")}`;

    return {
      moment,
      isLoading: false,
      user: JSON.parse(localStorage.getItem("userData")),
      date: formattedDate,
      formPayload: {
        closing_cash: 0,
      },
      detailResultList: {},
      isAdditionalCash: false,
      isCashChange: false,
    };
  },
  props: {
    getData: {
      type: Function,
    },
    detailResult: {
      type: Object,
    },
  },
  watch: {
    detailResult(newValue) {
      this.detailResultList = newValue;
      this.formPayload.closing_cash = this.detailResultList.cash_summary;
      this.setRupiah();
    },
  },
  methods: {
    setRupiah() {
      // const rupiahValue = this.formatRupiah(this.formPayload.closing_cash.toString());
      this.formPayload.closing_cash = this.formatRupiah(
        this.formPayload.closing_cash.toString()
      );
      this.detailResultList.opening_cash = this.formatRupiah(
        this.detailResultList.opening_cash.toString()
      );
      this.detailResultList.cash_amount = this.formatRupiah(
        this.detailResultList.cash_amount.toString()
      );
      this.detailResultList.cash_change = this.formatRupiah(
        this.detailResultList.cash_change.toString()
      );
      this.detailResultList.cash_out = this.formatRupiah(
        this.detailResultList.cash_out.toString()
      );
      this.detailResultList.additional_cash = this.formatRupiah(
        this.detailResultList.additional_cash.toString()
      );
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, "").toString();
      const split = number_string.split(",");
      const sisa = split[0].length % 3;
      let rupiah = split[0].substr(0, sisa);
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        const separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
      return prefix == undefined ? rupiah : rupiah || "";
    },
    formatToRupiah(amount) {
      const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    closeModal() {
      this.cleanUpForm();
      this.$bvModal.hide("modal-close-cash");
    },
    cleanUpForm() {
      this.formPayload.closing_cash = 0;
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (key === "closing_cash") {
            // eslint-disable-next-line radix
            form.append(
              key,
              parseFloat(this.preformatFloat(this.formPayload[key].toString()))
            );
          } else {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      return form;
    },
    closeItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      this.$store
        .dispatch("pettyCash/postClose", {
          query: "",
          payload: form,
          uuid: this.detailResultList.uuid,
        })
        .then(() => {
          this.getData();
          successNotification(this, "Success", "Kas Kecil berhasil ditutup");
          this.cleanUpForm();
          this.$bvModal.hide("modal-close-cash");
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    updateFormattedDate() {
      const newDate = new Date();
      const momentDate = moment(newDate);
      const days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      this.date = `${days[momentDate.day()]}, ${momentDate.format("DD")} ${
        months[momentDate.month()]
      } ${momentDate.format("YYYY HH:mm")}`;
    },
    goToCashInDetail() {
      const openingTime = this.detailResultList.opening_time; //Output: Friday, 2 August 2024 10:08

      moment.locale("en");

      console.log('opening time', openingTime)

      // Parse the opening time with the correct format
      const date = moment(openingTime, "dddd, D MMMM YYYY HH:mm");

      // Format the date to YYYY-MM-DD
      const formattedDate = date.format("YYYY-MM-DD");

      const routeParams = {
        name: "transaction.index",
        query: { date: formattedDate },
      };
      const routePath = this.$router.resolve(routeParams).href;
      window.open(routePath, "_blank");
    },
    goToCashOutDetail() {
      const openingTime = this.detailResultList.opening_time;
      // Set the locale to Indonesian
      moment.locale("id");

      // Parse the opening time
      const date = moment(openingTime, "dddd, DD MMMM YYYY HH.mm");

      // Format the date to YYYY-MM-DD
      const formattedDate = date.format("YYYY-MM-DD");
      const routeParams = {
        name: "payout.index",
        query: { date: formattedDate },
      };
      const routePath = this.$router.resolve(routeParams).href;
      window.open(routePath, "_blank");
    },
    goToAdditionalCashDetail() {
      this.$bvModal.hide("modal-close-cash");
      this.isAdditionalCash = true;
      this.$bvModal.show("modal-close-cash");
    },
    goBack(name) {
      this.$bvModal.hide("modal-close-cash");
      if (name === "additional") {
        this.isAdditionalCash = false;
      } else {
        this.isCashChange = false;
      }
      this.$bvModal.show("modal-close-cash");
    },
    goToCashChangeDetail() {
      this.$bvModal.hide("modal-close-cash");
      this.isCashChange = true;
      this.$bvModal.show("modal-close-cash");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
.modal-content {
  border-radius: 16px;
  .modal-header {
    background-color: #fff !important;
    border-bottom: 1px solid #eee !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .modal-title {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .modal-body {
    padding: 0;
  }
}

.button__group {
  display: flex;
  width: 100%;

  .button__cancel {
    background-color: #ffffff !important;
    border-color: #fff !important;
    color: $dark !important;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 0px 16px;
    width: 100%;
    height: 72px;
    font-size: 16px;
    font-weight: 800;
  }

  .button__save {
    background-color: #45b6ab !important;
    border-color: #45b6ab !important;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 16px 0px;
    width: 100%;
    height: 72px;
    font-size: 16px;
    font-weight: 800;
  }
}
</style>
