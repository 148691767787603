<template>
    <div class="table__container">
        <header-page>
            Kas Kecil
        </header-page>

        <TableCash />
    </div>
</template>

<script>
import _ from 'lodash'
import {
    BRow, BCol, BFormInput, BButton, BButtonGroup, BDropdown, BDropdownItem, BBadge, BImg, BInputGroup, BInputGroupAppend, BFormSelect, BSpinner,
} from 'bootstrap-vue'
import { checkPermission, errorNotification } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import HeaderPage from '@/components/HeaderPage.vue'
import TableCash from '@/components/Cash/TableCash.vue'

export default {
    title() {
        return 'Kas Kecil'
    },
    components: {
        BRow,
        VueGoodTable,
        BButton,
        BFormInput,
        HeaderPage,
        BButtonGroup,
        BDropdown,
        BDropdownItem,
        BBadge,
        BImg,
        BInputGroup,
        BInputGroupAppend,
        BFormSelect,
        BCol,
        BSpinner,
        TableCash,
    },
    setup() {
        return {
            checkPermission, errorNotification
        }
    },
    data() {
        return {
            
        }
    },
    watch: {

    },
    created() {

    },
    methods: {

    }
}
</script>

<style lang="scss">
// @import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.w-90{
    width: 90%;
}
</style>